<template>
  <data-detail :id="id" :category="category"></data-detail>
</template>

<script>
import dataDetail from '@/components/DataDetail.vue'

export default {
  name: 'AssociationDetail',

  components: { dataDetail },

  data () {
    return {
      id: '',
      category: ''
    }
  },

  created () {
    this.id = this.$route.query.id
    this.category = this.$route.query.category
  }
}
</script>

<style scoped>
</style>
